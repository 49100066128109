.page {
  &__container {
    min-height: 501px; // same as &__columns::before
    position: relative;
    z-index: 1;

    @media screen and (max-width: $break-xlarge) {
      overflow: hidden;
    }
  }

  &__columns {
    @extend %wrapper;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &::before,
    &::after {
      background: url("../../img/dist/side-leaves.png") no-repeat;
      background-size: 100%;
      content: "";
      display: block;
      height: 501px;
      position: absolute;
      top: calc(50% + #{$base-font-lh * 2});
      width: 134px;

      .page--no-intro & {
        top: 0;
      }
    }

    &::before {
      left: -$base-font-lh * 2;
      transform: translateX(-100%) translateY(-50%);

      .page--no-intro & {
        transform: translateX(-100%);
      }
    }

    &::after {
      right: -$base-font-lh * 2;
      transform: translateX(100%) translateY(-50%);

      .page--no-intro & {
        transform: translateX(100%);
      }
    }

    .page--no-sidebar & {
      margin: 0 auto;
      max-width: 50rem;
    }

    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }

  &__sidebar {
    width: 300px;

    &--no-image {
      .page--no-intro & {
        padding-top: $gap * 13;
      }
    }

    @media screen and (max-width: $break-small) {
      padding-top: $base-font-lh !important;
      width: 240px;
    }

    @media screen and (max-width: $break-xsmall) {
      width: auto;
    }
  }

  &__news {
    border-bottom: 1px solid $border-color;
    margin-bottom: $base-font-lh * 2;
    padding: $base-font-lh * 2 0 $base-font-lh;

    @media screen and (max-width: $break-small) {
      margin-bottom: $base-font-lh;
      padding: $base-font-lh 0 0;
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__contact {
    margin-bottom: $base-font-lh;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
    padding-right: $base-font-lh * 2;

    .page--no-sidebar & {
      padding-right: 0;
    }

    @media screen and (max-width: $break-medium) {
      padding-right: $base-font-lh;
    }

    @media screen and (max-width: $break-small) {
      padding-right: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    min-height: 270px;

    .page--no-intro & {
      min-height: 0;
    }
  }

  &__intro {
    color: $white;
    font-size: $xlarge-font-size;
    line-height: $xlarge-font-lh;
  }

  &__navigation {
    display: flex;
    list-style: none;
    margin: auto 0 0;

    li {
      margin-left: 2rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__main {
    padding: $base-font-lh * 2 0 $base-font-lh;

    @media screen and (max-width: $break-small) {
      padding: $base-font-lh 0 0;
    }
  }

  &--no-intro {
    background-color: $white;
    margin-top: 0;
    padding: $base-font-lh * 2 0;

    @media screen and (max-width: $break-medium) {
      padding: $base-font-lh 0;
    }

    .page__sidebar,
    .page__news,
    .page__main {
      padding-top: 0;
    }
  }

  &__slides--top {
    margin-bottom: $base-font-lh;
  }
}
