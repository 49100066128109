// ----- Font stacks
$font-primary: "Source Sans Pro", sans-serif;
$font-secondary: $font-primary;

// Hide text
@mixin no-text {
  font-size: 0px;
  line-height: 0px;
  text-indent: -999999px;
}

// Text stroke
@mixin text-stroke($color: red, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color,
    #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color,
    0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

// Angled edge
@mixin angle-edge(
  $angle,
  $angle-position-y,
  $angle-position-x,
  $angle-position-bottom-x: "",
  $fallback: true,
  $fallback-color: #fff
) {
  position: relative;
  overflow: hidden;

  // Converts degrees to VW, 100vw = 45deg using this technique
  @if $angle > 45 {
    $angle: 0;
    @error 'Invalid angle, it must be between 1-45';
  }
  @if $angle < 46 {
    $angle: convertDegToVW($angle);
  }

  @if $angle-position-bottom-x == "" {
    $angle-position-bottom-x: $angle-position-x;
  }

  $angle-calc-top: calc(0% + #{$angle}vw);
  $angle-calc-bottom: calc(100% - #{$angle}vw);

  $clip-path-top: 0 0, 100% 0;
  $clip-path-bottom: 100% 100%, 0 100%;

  $border-width-top: "";
  $border-width-bottom: "";
  $border-color-top: "";
  $border-color-bottom: "";

  @if $angle-position-y == "top" or $angle-position-y == "both" {
    @if $angle-position-x == "left" {
      $clip-path-top: 0 $angle-calc-top, 100% 0;

      $border-width-top: #{$angle + 1}vw 100vw 0 0;
    }

    @if $angle-position-x == "right" {
      $clip-path-top: 0 0, 100% $angle-calc-top;

      $border-width-top: #{$angle + 1}vw 0 0 100vw;
    }

    $border-color-top: $fallback-color transparent transparent transparent;
  }

  @if $angle-position-y == "bottom" or $angle-position-y == "both" {
    @if $angle-position-y ==
      "both" and
      $angle-position-x !=
      $angle-position-bottom-x
    {
      $angle-position-x: $angle-position-bottom-x;
    }

    @if $angle-position-x == "left" {
      $clip-path-bottom: 100% 100%, 0 $angle-calc-bottom;

      $border-width-bottom: 0 100vw #{$angle + 1}vw 0;
    }

    @if $angle-position-x == "right" {
      $clip-path-bottom: 100% $angle-calc-bottom, 0 100%;

      $border-width-bottom: 0 0 #{$angle + 1}vw 100vw;
    }

    $border-color-bottom: transparent transparent $fallback-color transparent;
  }

  $clip-path: polygon($clip-path-top, $clip-path-bottom);
  clip-path: $clip-path;

  // Fallback for clip-path with solid colours
  @if $fallback {
    @supports not (clip-path: $clip-path) {
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        z-index: 10;
        display: block;
        border-style: solid;
      }

      @if $angle-position-y == "top" or $angle-position-y == "both" {
        &::before {
          top: 0;
          border-width: $border-width-top;
          border-color: $border-color-top;
        }
      }

      @if $angle-position-y == "bottom" or $angle-position-y == "both" {
        &::after {
          bottom: 0;
          border-width: $border-width-bottom;
          border-color: $border-color-bottom;
        }
      }
    }
  }
}

@function convertDegToVW($angle) {
  @return round($angle * 2.22);
}

/* This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top); */
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;

  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}

// Helper classes
.hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

.clear {
  clear: both;
}
