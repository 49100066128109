.hero {
  background-color: $blue;
  height: 100vh;
  min-height: 480px;
  max-height: 600px;
  position: relative;
  text-align: center;

  &__video-container {
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    .iframe-container {
      height: 100%;
      padding-top: 0 !important;
    }
  }

  &__video {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    width: 100%;
  }

  &__slides {
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    .slick-list,
    .slick-track {
      height: 100%;
    }
  }

  &__slide {
    height: 100%;
    width: 100%;

    img {
      @include object-fit(cover, center);
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &__caption {
    bottom: 0;
    color: $white;
    padding: $base-font-lh;
    position: absolute;
    right: 0;
    text-align: right;
    width: 100%;
    z-index: 2;

    h1,
    h2 {
      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: inherit;
      font-size: $xxxlarge-font-size;
      line-height: $xxxlarge-font-lh;
      margin-bottom: $base-font-lh * 0.25;
      position: relative;
    }

    h2 {
      color: $yellow;

      &::after {
        opacity: 0.5;
      }
    }
  }
}
