// Project specific variables
$border-radius: 0;
$border-width: 4px;
$gap: 20px;
$wrapper-width: 980px;
$container-width: 1280px;
$header-height: 128px;

// Sizes for desktop first responsive
$break-xlarge: 1279px; // @media screen and (max-width: $break-xlarge)
$break-large: 1059px; // @media screen and (max-width: $break-large)
$break-medium: 869px; // @media screen and (max-width: $break-medium)
$break-small: 749px; // @media screen and (max-width: $break-small)
$break-xsmall: 479px; // @media screen and (max-width: $break-xsmall)

// Brand Colours
$white: #fff;
$black: #000;
$lightgrey: #eaeaea;
$blue: #1473e6;
$lightblue: #1d5599;
$green: #265e37;
$yellow: #ede926;
$lime: #b3c934;
$orange: #fbaa2d;
$red: #d10000;

$html-background-color: $white;
$body-background-color: $white;
$default-text-color: $green;
$placeholder-color: rgba($white, 0.5);
$default-link-color: $lightblue;
$hover-link-color: $blue;
$border-color: $green;

// Fonts
$xsmall-font-size: 12px;
$xsmall-font-lh: 16px;
$small-font-size: 14px;
$small-font-lh: 21px;
$base-font-size: 16px;
$base-font-lh: 24px;
$large-font-size: 24px;
$large-font-lh: 32px;
$xlarge-font-size: 30px;
$xlarge-font-lh: 38px;
$xxlarge-font-size: 36px;
$xxlarge-font-lh: 44px;
$xxxlarge-font-size: 48px;
$xxxlarge-font-lh: 56px;

$light-font-weight: 300;
$base-font-weight: 400;
$bold-font-weight: 700;

// Animation variables etc.
$box-shadow: 0 4px 15px 10px rgba($black, 0.1);
$transition-speed: 0.1s;
$transition-timing: ease-in-out;
