.footer {
  background-color: $green;
  color: $white;
  font-size: $small-font-size;
  line-height: $small-font-lh;
  position: relative;
  padding: $base-font-lh * 2 0;

  a {
    color: $white;

    &:hover {
      opacity: 0.75;
    }
  }

  &__inner {
    @extend %wrapper;
    position: relative;
    text-align: center;
    z-index: 1;

    &::before {
      background: url("../../img/dist/logo.png") no-repeat;
      background-size: 100%;
      content: "";
      display: block;
      height: 101px;
      margin: 0 auto $base-font-lh;
      position: relative;
      width: 90px;
    }
  }

  &__navigation {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 0 $base-font-lh * 0.5;

    @media screen and (max-width: $break-small) {
      display: block;
    }

    li {
      &::before {
        content: "|";
        padding: 0 0.5rem;

        @media screen and (max-width: $break-small) {
          content: none;
        }
      }

      &:first-child::before {
        content: none;
      }
    }
  }

  &__disclaimer {
    margin-left: auto;

    a {
      font-weight: $bold-font-weight;
      text-decoration: none;
    }
  }

  &__background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    &::after {
      background-color: rgba($green, 0.9);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__background-image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    width: 100%;
  }
}
