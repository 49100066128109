input[type="text"],
input[type="number"],
input[type="search"],
input[type="email"],
textarea,
select,
button {
  -webkit-appearance: none;
  background-color: $white;
  border: 1px solid $blue;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $default-text-color;
  display: block;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  height: $base-font-lh * 2;
  padding: 0 $base-font-lh/2;
  transition: all 0.1s ease-in-out;
  width: 100%;

  &:focus {
    border-color: $green;
    outline: none;
  }

  &.error,
  &.wpcf7-not-valid {
    background-color: rgba($red, 0.25);

    &:focus {
      background-color: rgba($green, 0.3);
    }
  }
}

input[type="file"] {
  display: block;
  width: 100%;
}

textarea {
  height: auto;
  line-height: $base-font-lh;
  padding: $base-font-lh/2;
}

.select {
  position: relative;

  select {
    padding-right: 36px;
  }

  i {
    color: $default-text-color;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

::-webkit-input-placeholder {
  color: $placeholder-color;
  font-style: normal !important;
}

:-moz-placeholder {
  color: $placeholder-color;
  font-style: normal !important;
}

::-moz-placeholder {
  color: $placeholder-color;
  font-style: normal !important;
}

:-ms-input-placeholder {
  color: $placeholder-color;
  font-style: normal !important;
}

label {
  display: block;
  padding-bottom: $base-font-lh/2;

  span {
    color: $red;
  }
}

.row {
  padding-bottom: $base-font-lh;

  &.actions {
    padding-top: $base-font-lh/2;
  }
}

body div.wpcf7 {
  padding-top: 0;

  span.wpcf7-not-valid-tip {
    display: none !important;
  }

  span.wpcf7-list-item {
    margin: 0;

    .wpcf7-list-item-label {
      color: $green;
    }
  }

  input[type="submit"] {
    display: block;
    margin: 0 auto;
  }

  span.ajax-loader {
    background: url("img/ajax-loader.gif");
    background-size: 100%;
    display: block;
    height: $base-font-lh;
    margin: $base-font-lh auto 0;
    width: $base-font-lh;
  }

  div.wpcf7-response-output {
    border: 1px solid $green;
    box-sizing: border-box;
    color: $green;
    margin: 0 auto 0;
    padding: $gap/2;
    text-align: center;

    @media screen and (max-width: $break-xsmall) {
      margin: 0 auto $gap;
      width: 100%;
    }
  }
}
