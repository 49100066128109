.post {
  .blog & {
    border-bottom: 1px solid $border-color;
    margin-bottom: $base-font-lh * 2;
    padding-bottom: $base-font-lh;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__meta {
    font-weight: $bold-font-weight;
    margin-bottom: $base-font-lh * 0.5;
  }
}

.single {
  &__share {
    border-top: 1px solid $border-color;
    margin-top: $base-font-lh * 2;
    padding-top: $base-font-lh * 2;
  }

  .socials {
    padding-top: $base-font-lh;

    a {
      display: inline-block;
      font-size: $large-font-size;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
