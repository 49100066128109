.header {
  background-color: rgba($white, 0.9);
  margin-left: -$base-font-lh * 0.5;
  padding: $base-font-lh 0;
  position: sticky;
  top: 0;
  width: calc(100% + #{$base-font-lh});
  z-index: 999;

  @media screen and (max-width: $break-medium) {
    padding: $base-font-lh * 0.5 0;
  }

  .admin-bar & {
    top: 32px;

    @media screen and (max-width: 782px) {
      top: 46px;
    }

    @media screen and (max-width: 600px) {
      top: 0;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__logo {
    background: url("../../img/dist/logo.png") no-repeat;
    background-size: 100%;
    color: $default-text-color;
    display: block;
    font-weight: $bold-font-weight;
    height: 194px;
    left: $base-font-lh * 1.5;
    position: absolute;
    top: $base-font-lh * 4;
    transition: none;
    width: 173px;

    &--default {
      @include no-text;
    }

    &--scrolled,
    &.inactive,
    .no-hero &,
    .no-hero-video & {
      align-items: center;
      background-position: left 2px;
      background-size: $base-font-lh * 2;
      display: flex;
      font-size: $large-font-size;
      height: $base-font-lh * 3;
      left: $base-font-lh;
      line-height: inherit;
      padding-left: 3.5rem;
      text-indent: 0;
      top: 0;
      width: auto;

      @media screen and (min-width: $break-medium) and (max-width: $break-large),
        screen and (max-width: $break-xsmall) {
        @include no-text;
      }
    }
  }

  &__navigation {
    flex: 1;
    padding: 0 $base-font-lh * 0.5;
    text-align: right;

    .menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;

      @media screen and (max-width: $break-medium) {
        display: none;
        padding: 0 $base-font-lh * 0.5;
      }
    }

    .menu-item {
      padding: 0;
      position: relative;

      a {
        align-items: center;
        border: 0;
        display: inline-flex;
        padding: 0 1rem;

        &:hover,
        &:focus {
          opacity: 0.75;
        }

        &:focus {
          outline: none;
        }

        @media screen and (max-width: $break-medium) {
          padding: 0 $base-font-lh * 0.5;
        }
      }

      &.current-menu-item > a {
        font-weight: $bold-font-weight;
      }

      &.menu-item-has-children {
        > a {
          &::after {
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: "";
            display: inline-block;
            height: 6px;
            left: 0.5rem;
            position: relative;
            top: -1px;
            transition: all $transition-speed * 3 $transition-timing;
            transform: rotate(135deg);
            width: 6px;

            @media screen and (max-width: $break-medium) {
              content: none;
            }
          }
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &:first-child {
        a {
          padding-left: 0;
        }

        &::before {
          content: none;
        }
      }
    }

    .sub-menu {
      border-top: $base-font-lh solid transparent;
      display: block;
      left: 4px;
      list-style: none;
      margin: 0;
      min-width: 160px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      text-align: left;
      transition: all $transition-speed $transition-timing;
      top: 100%;
      z-index: 20;

      a {
        padding: 4px 12px !important;
        transition: color $transition-speed $transition-timing;

        &:hover {
          color: $hover-link-color;
          opacity: 1;
        }
      }

      li {
        background: rgba($white, 0.9);

        &:first-child a {
          padding-top: 0 !important;
        }

        &:last-child {
          padding-bottom: 4px;
        }
      }

      @media screen and (max-width: $break-medium) {
        display: none !important;
      }
    }

    .menu-toggle {
      color: $default-link-color;
      display: none;
      padding: $base-font-lh * 0.5 $base-font-lh;
      text-align: right;
      text-transform: uppercase;

      i {
        margin-right: 2px;
        min-width: 14px;
      }

      &:hover {
        color: $hover-link-color;
      }

      @media screen and (max-width: $break-medium) {
        display: inline-block;
      }
    }
  }

  &__strapline {
    font-family: $font-secondary;
    font-size: $xxlarge-font-size;
    line-height: $xxlarge-font-lh;
    padding: 0 0 $base-font-lh * 2;

    span {
      &:nth-child(1) {
        color: $yellow;
      }

      &:nth-child(2) {
        color: $lime;
      }

      &:nth-child(3) {
        color: $orange;
      }
    }
  }
}
