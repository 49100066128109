// Slick overrides
.slick-prev,
.slick-next {
  height: $gap * 1.5;
  width: $gap * 1.5;
  z-index: 1;

  .slick-prev-icon,
  .slick-next-icon {
    &::before {
      font-size: $gap * 1.5;
    }
  }

  &:hover {
    background: transparent;
    opacity: 0.75;
  }

  &:focus {
    background: transparent;
  }
}

.slick-prev {
  left: $gap;
}

.slick-next {
  right: $gap;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;

  .page__content & {
    display: flex !important;
    float: none;
  }

  > div {
    height: 100%;
  }

  img {
    @include object-fit(cover, center);
    display: block !important;
    height: 100%;
    width: 100%;
  }
}
