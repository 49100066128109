html {
  background: $html-background-color;
  margin-top: 0 !important;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &.touch {
    @media screen and (max-width: 782px) {
      margin-top: 0 !important;
    }
  }

  &.no-js,
  &.js {
    @media screen and (max-width: 600px) {
      margin-top: 0 !important;

      #wpadminbar {
        display: none !important;
      }
    }
  }
}

body {
  background: $body-background-color;
  color: $default-text-color;
  font-family: $font-primary;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-font-lh;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 $base-font-lh * 0.5 $base-font-lh * 0.5;

  &.no-scroll {
    overflow-y: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $base-font-lh;
  font: $bold-font-weight #{$base-font-size}/#{$base-font-lh} $font-secondary;

  &.stripe {
    &::after {
      background: linear-gradient(
        to right,
        rgba($white, 0) 0%,
        rgba($white, 0.25) 50%,
        rgba($white, 0) 100%
      );
      content: "";
      display: block;
      height: 1px;
      margin: $base-font-lh auto 0;
      width: $gap * 10;
    }

    a {
      border-bottom: 0;
    }
  }
}

h1 {
  font-size: $xxlarge-font-size;
  line-height: $xxlarge-font-lh;

  @media screen and (max-width: $break-small) {
    font-size: $xlarge-font-size;
    line-height: $xlarge-font-lh;
  }
}

h2 {
  font-size: $xlarge-font-size;
  line-height: $xlarge-font-lh;

  @media screen and (max-width: $break-small) {
    font-size: $large-font-size;
    line-height: $large-font-lh;
  }
}

h3 {
  font-size: $large-font-size;
  line-height: $large-font-lh;

  @media screen and (max-width: $break-small) {
    font-size: $base-font-size;
    line-height: $base-font-lh;
  }
}

strong {
  font-weight: $bold-font-weight;
}

em {
  font-style: italic;
}

a {
  color: $default-link-color;
  text-decoration: none;
  transition: all $transition-speed $transition-timing;

  &:hover {
    color: $hover-link-color;
  }
}

img {
  max-width: 100%;
}

ul,
ol,
p,
table,
address,
dl {
  margin: 0 0 $base-font-lh;
  padding: 0;
  font-style: normal;
}

ul {
  list-style: disc outside;
  margin-left: $gap;
}

ol {
  list-style: decimal outside;
  margin-left: $gap;

  li {
    margin-bottom: $base-font-lh/2;
  }
}

table {
  border-collapse: collapse;
  width: 100%;

  th {
    background: $lightgrey;

    text-align: left;
  }

  th,
  td {
    padding: $gap/2;
    border: 1px solid $lightgrey;
  }

  tr:nth-child(even) {
    background: lighten($lightgrey, 2.5%);
  }

  tbody th {
    background: none;
  }
}

dl {
  dt {
    font-weight: $bold-font-weight;
    padding-bottom: $base-font-lh;
  }

  dd {
    margin: 0;
  }
}

small {
  font-size: $small-font-size;
  line-height: $small-font-lh;
}

hr {
  background: $border-color;
  border: 0;
  clear: both;
  display: block;
  height: 1px;
  margin: $base-font-lh * 1.5 0;
  padding: 0;
}

%wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $wrapper-width;
  width: 100%;

  &.col2 {
    align-items: center;
    display: flex;

    > * {
      width: 50%;

      &:first-child {
        padding-right: $base-font-lh;

        @media screen and (max-width: $break-small) {
          padding-right: 0;
        }
      }

      &:last-child {
        padding-left: $base-font-lh;

        @media screen and (max-width: $break-small) {
          padding-left: 0;
        }
      }
    }

    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }

  @media screen and (max-width: $break-large) {
    margin: 0 $base-font-lh * 2;
    max-width: none;
    width: auto;
  }

  @media screen and (max-width: $break-medium) {
    margin: 0 $base-font-lh;
  }

  @media screen and (max-width: $break-small) {
    margin: 0 $base-font-lh * 0.5;
  }
}

#map {
  display: block;
  height: $gap * 14;
}
