input[type="submit"],
button,
.btn,
.wpcf7-submit {
  background-color: $default-link-color;
  border: 0;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-primary;
  font-size: $base-font-size;
  letter-spacing: 0.5px;
  line-height: $base-font-lh;
  height: auto;
  padding: $base-font-lh/2 $base-font-lh;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
  width: auto;

  i {
    font-size: $gap;
    margin-right: $base-font-lh/4;
    position: relative;
    top: 1px;
  }

  &:hover {
    background-color: $hover-link-color;
    color: $white;
    outline: none;
  }

  &:focus {
    background-color: darken($default-link-color, 15%);
    outline: none;
  }

  @media screen and (max-width: $break-xsmall) {
    display: block;
    padding-left: $base-font-lh;
    padding-right: $base-font-lh;
  }
}
